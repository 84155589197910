import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import moment from 'moment';
import PaperBase from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const Paper = styled(PaperBase)`
  padding: ${({ theme }) => theme.spacing(3)}px;
`;

const MessageText = styled(Typography).attrs({
  variant: 'h5'
})`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const Description = styled(Typography).attrs({
  variant: 'body1',
  paragraph: true,
  color: 'textSecondary'
})`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

const MessageDetails = ({ message }) => (
  <Paper>
    <Typography variant="subtitle2">
      {message.messageId}
    </Typography>
    <Typography variant="caption" color="textSecondary" gutterBottom>
      Updated {moment(message.updatedAt).fromNow()}
    </Typography>
    <MessageText>{message.defaultMessage}</MessageText>
    {message.description && <Description>{message.description}</Description>}
  </Paper>
);

MessageDetails.fragments = {
  message: gql`
    fragment MessageDetails_message on Message {
      messageId
      defaultMessage
      description
      updatedAt
    }
  `
};

export default MessageDetails;
