import qs from 'query-string';

const paths = {
  login: () => '/login',
  namespace: (namespace, filter = {}) => {
    const { q, languages = {} } = filter;
    const params = {
      q,
      languages: Object.keys(languages).filter(key => languages[key])
    };
    const queryString = qs.stringify(params, { arrayFormat: 'bracket' });
    return queryString === ''
      ? `/namespaces/${namespace}`
      : `/namespaces/${namespace}?${queryString}`;
  },
  dashboard: () => '/',
  namespaces: () => '/namespaces',
  languages: () => '/languages',
  users: () => '/users',
  apiKeys: () => '/api-keys',
  'api-keys': () => '/api-keys',
  home: () => paths.dashboard()
};

export default paths;
