import React from 'react';
import styled from 'styled-components';
import TextField from 'components/TextField';
import SwitchField from 'components/SwitchField';
import { Formik, Form, Field } from 'formik';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormErrorMessage from 'containers/FormErrorMessage';
import { getValidationErrors } from 'utils/serverError';

const Container = styled.div``;

const LanguageDialog = ({ onSubmit, onClose, initialValues }) => {
  const handleSubmit = async (values, { setSubmitting, setStatus, setErrors }) => {
    try {
      await onSubmit(values);
      setSubmitting(false);
      onClose();
    } catch (error) {
      const validationErrors = getValidationErrors(error);
      if (validationErrors) {
        setErrors(validationErrors);
      }
      setStatus({ error });
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, status }) => (
          <Form>
            <DialogTitle>
              {initialValues.id !== '' ? 'Update language' : 'Add language'}
            </DialogTitle>
            <DialogContent>
              <Field
                component={SwitchField}
                name="isPublished"
                label="Published"
                color="primary"
              />
              <Field
                type="text"
                component={TextField}
                margin="normal"
                fullWidth
                label="Language key"
                name="key"
              />
              <Field
                type="text"
                component={TextField}
                margin="normal"
                fullWidth
                label="Name"
                name="name"
              />
              <Field
                type="text"
                component={TextField}
                margin="normal"
                fullWidth
                label="Currency key"
                name="currencyKey"
              />
              <Field
                type="text"
                component={TextField}
                margin="normal"
                fullWidth
                label="Currency symbol"
                name="currency"
              />
              <FormErrorMessage status={status} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LanguageDialog;
