import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AddButton from 'components/AddButton';
import Dialog from '@material-ui/core/Dialog';
import { UserRole } from 'consts';
import UserListItem from '../components/UserListItem';
import UserForm from '../components/UserForm';
import DeleteForm from '../components/DeleteForm';

const Container = styled.div``;

const UsersFragment = gql`
  fragment Users_user on User {
    id
    name
    namespaces
    ...UserListItem_user
    ...UserForm_user
  }
  ${UserListItem.fragments.user}
  ${UserForm.fragments.user}
`;

const UsersQuery = gql`
  query UsersQuery {
    users @rest(type: "[User]", path: "users/") {
      ...Users_user
    }
  }
  ${UsersFragment}
`;

const UsersContainerQuery = gql`
  query UsersContainerQuery {
    users @rest(type: "[User]", path: "users/") {
      ...Users_user
    }
    namespaceKeys @rest(type: "[String!]", path: "namespace-keys/")
  }
  ${UsersFragment}
`;

const CreateUserMutation = gql`
  mutation CreateUserMutation($input: CreateUserInput!) {
    createUser(input: $input) @rest(type: "User", path: "users/", method: "POST") {
      ...Users_user
    }
  }
  ${UsersFragment}
`;

const UpdateUserMutation = gql`
  mutation UpdateUserMutation($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) @rest(type: "User", path: "users/{args.id}", method: "PUT") {
      ...Users_user
    }
  }
  ${UsersFragment}
`;

const DeleteUserMutation = gql`
  mutation DeleteUserMutation($id: ID!) {
    deleteUser(id: $id)
      @rest(type: "DeleteUserMutation", path: "users/{args.id}", method: "DELETE") {
      user @type(name: "User") {
        id
      }
    }
  }
`;

const Users = () => {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <Mutation
      mutation={CreateUserMutation}
      update={(cache, { data: { createUser } }) => {
        const { users } = cache.readQuery({ query: UsersQuery });
        cache.writeQuery({
          query: UsersQuery,
          data: { users: [createUser, ...users] }
        });
      }}
    >
      {createUser => (
        <Mutation mutation={UpdateUserMutation}>
          {updateUser => (
            <Mutation
              mutation={DeleteUserMutation}
              update={(cache, { data: { deleteUser } }) => {
                const { users } = cache.readQuery({ query: UsersQuery });
                cache.writeQuery({
                  query: UsersQuery,
                  data: { users: users.filter(({ id }) => deleteUser.user.id !== id) }
                });
              }}
            >
              {deleteUser => (
                <Query query={UsersContainerQuery}>
                  {({ loading, error, data: { users, namespaceKeys } }) => {
                    if (loading) return 'Loading...';
                    if (error) return `Error! ${error.message}`;

                    const handleCreateClick = () => handleEditDialogOpen();

                    const handleEditClick = id => () => handleEditDialogOpen(id);
                    const handleEditDialogOpen = id => {
                      handleDeleteDialogClose();
                      setSelectedUser(users.find(user => user.id === id));
                      setEditDialogOpen(true);
                    };
                    const handleEditDialogClose = () => setEditDialogOpen(false);

                    const handleDeleteClick = id => () => handleDeleteDialogOpen(id);
                    const handleDeleteDialogOpen = id => {
                      handleEditDialogClose();
                      setSelectedUser(users.find(user => user.id === id));
                      setDeleteDialogOpen(true);
                    };
                    const handleDeleteDialogClose = () => setDeleteDialogOpen(false);
                    const handleDeleteSubmit = async ({ id }) => {
                      await deleteUser({ variables: { id } });
                      handleDeleteDialogClose();
                    };

                    const handleSubmit = async ({ id, email, password, ...input }) => {
                      if (id === '') {
                        await createUser({ variables: { input: { ...input, email, password } } });
                      } else {
                        await updateUser({ variables: { id, input } });
                      }
                      handleEditDialogClose();
                    };
                    const handleDialogExited = () => {
                      setSelectedUser(null);
                    };

                    return (
                      <Container>
                        <Grid container justify="center" spacing={0}>
                          <Grid item sm={12} md={8} lg={6}>
                            <Grid container direction="column" spacing={2}>
                              <Grid item>
                                <AddButton onClick={handleCreateClick}>Add user</AddButton>
                              </Grid>
                              <Grid item>
                                <Paper>
                                  <List disablePadding>
                                    {users.map((user, idx) => (
                                      <Fragment key={user.id}>
                                        <UserListItem
                                          user={user}
                                          userRole={
                                            user.namespaces && user.namespaces.length
                                              ? UserRole.EDITOR
                                              : UserRole.ADMIN
                                          }
                                          onEditClick={handleEditClick}
                                          onDeleteClick={handleDeleteClick}
                                        />
                                        {idx < users.length && <Divider />}
                                      </Fragment>
                                    ))}
                                  </List>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Dialog
                          open={isEditDialogOpen}
                          onClose={handleEditDialogClose}
                          onExited={handleDialogExited}
                        >
                          <UserForm
                            onClose={handleEditDialogClose}
                            onSubmit={handleSubmit}
                            namespaceKeys={namespaceKeys}
                            user={selectedUser}
                            initialValues={{
                              id: selectedUser ? selectedUser.id : '',
                              email: selectedUser ? selectedUser.email : '',
                              password: '',
                              name: selectedUser ? selectedUser.name || '' : '',
                              namespaces: selectedUser ? selectedUser.namespaces : []
                            }}
                          />
                        </Dialog>
                        <Dialog
                          open={isDeleteDialogOpen}
                          onClose={handleDeleteDialogClose}
                          onExited={handleDialogExited}
                        >
                          <DeleteForm
                            onClose={handleDeleteDialogClose}
                            onSubmit={handleDeleteSubmit}
                            type="user"
                            initialValues={{ id: selectedUser ? selectedUser.id : '' }}
                          />
                        </Dialog>
                      </Container>
                    );
                  }}
                </Query>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default Users;
