import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import paths from 'paths';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Center from 'components/Center';
import Page from 'components/Page';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Namespaces from './containers/Namespaces';
import Languages from './containers/Languages';
import ApiKeys from './containers/ApiKeys';
import Users from './containers/Users';

const Container = styled.div``;

const TabBar = styled(AppBar)`
  z-index: 0;
`;

const tabs = {
  namespaces: {
    title: 'Namespaces',
    component: Namespaces,
    permission: 'canQueryNamespaces'
  },
  languages: {
    title: 'Languages',
    component: Languages,
    permission: 'canQueryLanguages'
  },
  users: {
    title: 'Users',
    component: Users,
    permission: 'canQueryUsers'
  },
  'api-keys': {
    title: 'API keys',
    component: ApiKeys,
    permission: 'canQueryApiKeys'
  }
};

const DashboardPage = ({ viewer, match, history }) => {
  const { tab } = match.params;

  if (!tab) {
    return <Redirect to={paths.namespaces()} />;
  }

  const handleTabChange = (event, newTabKey) => {
    history.replace(paths[newTabKey]());
  };

  const TabComponent = tabs[tab].component;

  return (
    <Container>
      <Helmet>
        <title>{tabs[tab].title} | Cooltix Translate</title>
      </Helmet>
      <CssBaseline />
      <TabBar component="div" color="primary" position="static" elevation={0}>
        <Center>
          <Tabs value={tab} onChange={handleTabChange} textColor="inherit">
            {Object.keys(tabs).map(key =>
              viewer.userPermissions[tabs[key].permission] ? (
                <Tab key={key} value={key} label={tabs[key].title} />
              ) : null
            )}
          </Tabs>
        </Center>
      </TabBar>
      <Page>
        <Center>
          <TabComponent />
        </Center>
      </Page>
    </Container>
  );
};

DashboardPage.fragments = {
  viewer: gql`
    fragment DashboardPage_viewer on User {
      userPermissions {
        canQueryNamespaces
        canQueryLanguages
        canQueryUsers
        canQueryApiKeys
      }
    }
  `
};

export default DashboardPage;
