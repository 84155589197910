import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import CssBaseline from '@material-ui/core/CssBaseline';
import Center from 'components/Center';
import Page from 'components/Page';
import Header from 'components/Header';
import MobileStepperBase from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MessageDetails from '../components/MessageDetails';
import TranslationForm from '../components/TranslationForm';

const Container = styled.div``;

const MobileStepper = styled(MobileStepperBase)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const Content = styled.div``;

const MessageContainerFragment_messages = gql`
  fragment MessageContainer_messages on Message {
    id
    translations {
      id
      language
      message
    }
    ...TranslationForm_message
    ...MessageDetails_message
  }
  ${TranslationForm.fragments.message}
  ${MessageDetails.fragments.message}
`;

const UpdateTranslationsMutation = gql`
  mutation UpdateTranslationsMutation($id: ID!, $input: UpdateTranslationsInput!) {
    updateTranslations(id: $id, input: $input)
      @rest(type: "Message", path: "messages/{args.id}/translations", method: "PUT") {
      id
      ...MessageContainer_messages
    }
  }
  ${MessageContainerFragment_messages}
`;

const formatInitialValues = (languages, message) =>
  languages.reduce((acc, language) => {
    const translation = message.translations.find(
      translation => translation.language === language.key
    );
    acc = {
      ...acc,
      [language.key]: translation ? translation.message : ''
    };
    return acc;
  }, {});

const MessageContainer = ({ messages, languages, initialIndex, onCancel }) => {
  const [activeStep, setActiveStep] = useState(initialIndex);
  const maxSteps = messages.length;

  const activeMessage = messages[activeStep];

  const handleNext = () => setActiveStep(prevStep => prevStep + 1);
  const handleBack = () => setActiveStep(prevStep => prevStep - 1);

  return (
    <Mutation mutation={UpdateTranslationsMutation} key={activeMessage.id}>
      {updateTranslations => {
        const handleSubmit = async values => {
          const input = Object.keys(values)
            .map(language => ({ language, message: values[language] }))
            .filter(x => x.message !== '');
          return updateTranslations({ variables: { id: activeMessage.id, input } });
        };

        const initialValues = formatInitialValues(languages, activeMessage);

        return (
          <Container>
            <CssBaseline />
            <Header />
            <Page>
              <Center>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  variant="text"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      Next
                      <KeyboardArrowRight />
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                      <KeyboardArrowLeft />
                      Prev
                    </Button>
                  }
                />
                <Content>
                  <MessageDetails message={activeMessage} />
                  <TranslationForm
                    initialValues={initialValues}
                    languages={languages}
                    onSubmit={handleSubmit}
                    onCancel={onCancel}
                  />
                </Content>
              </Center>
            </Page>
          </Container>
        );
      }}
    </Mutation>
  );
};

MessageContainer.fragments = {
  messages: MessageContainerFragment_messages,
  languages: gql`
    fragment MessageContainer_languages on Language {
      id
      ...TranslationForm_languages
    }
    ${TranslationForm.fragments.languages}
  `
};

export default MessageContainer;
