import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import FormErrorMessage from 'containers/FormErrorMessage';

const Container = styled.div``;

const DeleteButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const DeleteForm = ({ initialValues, onClose, onSubmit, type = 'item' }) => {
  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      await onSubmit(values);
      setSubmitting(false);
    } catch (error) {
      setStatus({ error });
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, status }) => (
        <Form>
          <Container>
            <DialogTitle>Delete {type}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the selected {type}?
              </DialogContentText>
              <FormErrorMessage status={status} />
            </DialogContent>
            <DialogActions>
              <DeleteButton type="submit" disabled={isSubmitting}>
                Yes, delete
              </DeleteButton>
              <Button onClick={onClose} variant="contained" color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default DeleteForm;
