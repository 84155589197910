import styled from 'styled-components';

const Center = styled.div`
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;

  margin: 0 auto;
  padding: 0 20px;
`;

export default Center;
