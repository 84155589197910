import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Main from 'containers/Main';

import LoginPage from 'pages/LoginPage';

const App = () => (
  <Switch>
    <Route exact path="/login" component={LoginPage} />
    <Route path="/" component={Main}/>
  </Switch>
);

export default App;
