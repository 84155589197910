import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from 'components/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form as FormBase, Field } from 'formik';
import FormErrorMessage from 'containers/FormErrorMessage';
import { getValidationErrors } from 'utils/serverError';

const Paper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: ${({ theme }) => theme.spacing(8)}px;
`;

const Form = styled(FormBase)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

const SubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const LoginForm = ({ login, redirect }) => {
  const handleSubmit = async (values, { setSubmitting, setStatus, setErrors }) => {
    try {
      await login({ variables: { input: values } });
      setSubmitting(false);
      redirect();
    } catch (error) {
      const validationErrors = getValidationErrors(error);
      if (validationErrors) {
        setErrors(validationErrors);
      }
      setStatus({ error });
      setSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper>
        <Typography component="h1" variant="h5">
          Log In
        </Typography>
        <Formik initialValues={{ email: '', password: '' }} onSubmit={handleSubmit}>
          {({ isSubmitting, isValid, status }) => (
            <Form>
              <Field
                component={TextField}
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Field
                component={TextField}
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormErrorMessage status={status} />
              <SubmitButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting}
              >
                Log In
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default LoginForm;
