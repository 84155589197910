import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    error: {
      main: '#d32f2f'
    },
    primary: {
      main: '#167EE6'
    },
    secondary: {
      main: '#F6F4F3'
    },
    success: {
      main: '#29BF12'
    },
    warning: {
      main: '#FFAE03'
    },
    gray: {
      main: '#DCDCDC'
    }
  },
  typography: {}
});

export default theme;
