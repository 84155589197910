import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import Link from 'containers/Link';
import paths from 'paths';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Logo from 'components/Icon/Logo';
import CenterBase from 'components/Center';

const Center = styled(CenterBase)`
  padding: 0;
`;

const Title = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

const User = styled.div`
  margin-left: auto;
`;

const Header = ({ viewer, isLoggedIn }) => (
  <AppBar position="static" color="default">
    <Center>
      <Toolbar>
        <IconButton edge="start" component={Link} to={paths.home()}>
          <Logo />
        </IconButton>
        <Title variant="h6">Cooltix Translate</Title>
        {isLoggedIn && (
          <User>
            <Typography variant="subtitle1">{viewer.name || viewer.email}</Typography>
          </User>
        )}
      </Toolbar>
    </Center>
  </AppBar>
);

Header.fragments = {
  viewer: gql`
    fragment Header_viewer on User {
      name
      email
    }
  `
};

export default Header;
