const isValidationError = error => error && error.type && error.type === 'ApiError' && error.data;

export const getValidationErrors = (error = {}) => {
  const { networkError = {} } = error;
  const { result } = networkError;
  return isValidationError(result) ? result.data : null;
};

export const getErrorMessage = (error = {}, defaultMessage = 'Unknown error') => {
  const { networkError } = error;
  if (!networkError || isValidationError(networkError.result)) {
    return null;
  }
  return networkError.result ? networkError.result.message : defaultMessage;
};
