import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchField = ({ field, form, color, ...rest }) => (
  <FormControlLabel
    control={<Switch value={field.name} color={color} />}
    {...field}
    checked={!!field.value}
    {...rest}
  />
);

export default SwitchField;
