import styled from 'styled-components';

const Badge = styled.div`
  position: relative;
  display: inline-block;

  top: -1px;
  width: 8px;
  height: 8px;

  vertical-align: middle;
  border-radius: 50%;
  background-color: ${({ theme, status }) => theme.palette[status].main};
`;

export default Badge;
