import React, { useState } from 'react';
import styled from 'styled-components';
import TextField from 'components/TextField';
import { Formik, Form, Field } from 'formik';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormErrorMessage from 'containers/FormErrorMessage';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import gql from 'graphql-tag';
import { getValidationErrors } from 'utils/serverError';

const Container = styled.div``;

const UserForm = ({ namespaceKeys, onSubmit, onClose, initialValues, user }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isAdmin, setIsAdmin] = useState(!!initialValues.id && !initialValues.namespaces.length);

  const handleAdminSwitchChange = () => setIsAdmin(!isAdmin);
  const handleShowPasswordClick = () => setShowPassword(!showPassword);

  const handleSubmit = async (values, { setSubmitting, setStatus, setErrors }) => {
    try {
      await onSubmit({ ...values, namespaces: isAdmin ? [] : values.namespaces });
      setSubmitting(false);
    } catch (error) {
      const validationErrors = getValidationErrors(error);
      if (validationErrors) {
        setErrors(validationErrors);
      }
      setStatus({ error });
      setSubmitting(false);
    }
  };

  const isEditing = initialValues.id !== '';

  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, status, values }) => (
          <Form>
            <DialogTitle>{isEditing ? 'Update user' : 'Add user'}</DialogTitle>
            <DialogContent>
              <Field
                type="text"
                component={TextField}
                margin="normal"
                fullWidth
                label="Name (optional)"
                name="name"
                autoFocus
              />
              {!isEditing && (
                <>
                  <Field
                    type="text"
                    component={TextField}
                    margin="normal"
                    fullWidth
                    label="Email"
                    name="email"
                  />
                  <Field
                    type={showPassword ? 'text' : 'password'}
                    component={TextField}
                    margin="normal"
                    label="Password"
                    name="password"
                    fullWidth
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: values.password !== '' && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleShowPasswordClick}
                            disabled={isSubmitting}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </>
              )}

              {(!isEditing || user.permissions.canUpdateNamespaces) && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isAdmin}
                        onChange={handleAdminSwitchChange}
                        value="isAdmin"
                        color="primary"
                      />
                    }
                    label="Admin"
                  />

                  {!isAdmin && (
                    <Field
                      select
                      SelectProps={{ multiple: true }}
                      component={TextField}
                      margin="normal"
                      fullWidth
                      label="Namespaces"
                      name="namespaces"
                    >
                      {namespaceKeys.map(key => (
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      ))}
                    </Field>
                  )}
                </FormGroup>
              )}

              <FormErrorMessage status={status} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

UserForm.fragments = {
  user: gql`
    fragment UserForm_user on User {
      permissions {
        canUpdateNamespaces
      }
    }
  `
};

export default UserForm;
