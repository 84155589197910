import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import AddButton from 'components/AddButton';
import ApiKeyListItem from '../components/ApiKeyListItem';
import ApiKeyForm from '../components/ApiKeyForm';
import DeleteForm from '../components/DeleteForm';

const Container = styled.div``;

const ApiKeysFragment = gql`
  fragment ApiKeys_apiKey on ApiKey {
    id
    name
    ...ApiKeyListItem_apiKey
  }
  ${ApiKeyListItem.fragments.apiKey}
`;

const ApiKeysQuery = gql`
  query ApiKeysQuery {
    apiKeys @rest(type: "[ApiKey]", path: "apikeys/") {
      ...ApiKeys_apiKey
    }
  }
  ${ApiKeysFragment}
`;

const ApiKeysContainerQuery = gql`
  query ApiKeysContainerQuery {
    apiKeys @rest(type: "[ApiKey]", path: "apikeys/") {
      ...ApiKeys_apiKey
    }
    namespaceKeys @rest(type: "[String!]", path: "namespace-keys/")
  }
  ${ApiKeysFragment}
`;

const CreateApiKeyMutation = gql`
  mutation CreateApiKeyMutation($input: CreateApiKeyInput!) {
    createApiKey(input: $input) @rest(type: "ApiKey", path: "apikeys/", method: "POST") {
      ...ApiKeys_apiKey
    }
  }
  ${ApiKeysFragment}
`;

const DeleteApiKeyMutation = gql`
  mutation DeleteApiKeyMutation($id: ID!) {
    deleteApiKey(id: $id)
      @rest(type: "DeleteApiKeyPayload", path: "apikeys/{args.id}", method: "DELETE") {
      apiKey @type(name: "ApiKey") {
        id
      }
    }
  }
`;

const UpdateApiKeyMutation = gql`
  mutation UpdateApiKeyMutation($id: ID!, $input: UpdateApiKeyInput!) {
    updateApiKey(id: $id, input: $input)
      @rest(type: "ApiKey", path: "apikeys/{args.id}", method: "PUT") {
      ...ApiKeys_apiKey
    }
  }
  ${ApiKeysFragment}
`;

const ApiKeys = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState(null);

  return (
    <Mutation
      mutation={CreateApiKeyMutation}
      update={(cache, { data: { createApiKey } }) => {
        const { apiKeys } = cache.readQuery({ query: ApiKeysQuery });
        cache.writeQuery({
          query: ApiKeysQuery,
          data: { apiKeys: [createApiKey, ...apiKeys] }
        });
      }}
    >
      {createApiKey => (
        <Mutation
          mutation={DeleteApiKeyMutation}
          update={(cache, { data: { deleteApiKey } }) => {
            const { apiKeys } = cache.readQuery({ query: ApiKeysQuery });
            cache.writeQuery({
              query: ApiKeysQuery,
              data: { apiKeys: apiKeys.filter(({ id }) => deleteApiKey.apiKey.id !== id) }
            });
          }}
        >
          {deleteApiKey => (
            <Mutation mutation={UpdateApiKeyMutation}>
              {updateApiKey => (
                <Query query={ApiKeysContainerQuery}>
                  {({ loading, error, data: { apiKeys, namespaceKeys } }) => {
                    if (loading) return 'Loading...';
                    if (error) return `Error! ${error.message}`;

                    const handleEditClick = id => () => handleDialogOpen(id);
                    const handleDialogClose = () => {
                      setDialogOpen(false);
                    };
                    const handleDialogExited = () => {
                      setSelectedApiKey(null);
                    };
                    const handleDialogOpen = id => {
                      setSelectedApiKey(apiKeys.find(key => key.id === id));
                      setDialogOpen(true);
                    };

                    const handleDeleteClick = id => () => handleDeleteDialogOpen(id);
                    const handleDeleteDialogOpen = id => {
                      handleDialogClose();
                      setSelectedApiKey(apiKeys.find(key => key.id === id));
                      setDeleteDialogOpen(true);
                    };
                    const handleDeleteDialogClose = () => setDeleteDialogOpen(false);
                    const handleDeleteSubmit = async ({ id }) => {
                      await deleteApiKey({ variables: { id } });
                      handleDeleteDialogClose();
                    };

                    const handleSubmit = async ({ id, namespace, name }) => {
                      if (id === '') {
                        await createApiKey({ variables: { input: { namespace, name } } });
                      } else {
                        await updateApiKey({ variables: { id, input: { name } } });
                      }
                      handleDialogClose();
                    };

                    const handleCreateClick = () => handleDialogOpen();

                    return (
                      <Container>
                        <Grid container justify="center" spacing={0}>
                          <Grid item sm={12} md={8} lg={6}>
                            <Grid container direction="column" spacing={2}>
                              <Grid item>
                                <AddButton onClick={handleCreateClick}>Add API key</AddButton>
                              </Grid>
                              <Grid item>
                                <Paper>
                                  <List disablePadding>
                                    {apiKeys.map((apiKey, idx) => (
                                      <Fragment key={apiKey.id}>
                                        <ApiKeyListItem
                                          apiKey={apiKey}
                                          onDeleteClick={handleDeleteClick}
                                          onEditClick={handleEditClick}
                                        />
                                        {idx < apiKeys.length && <Divider />}
                                      </Fragment>
                                    ))}
                                  </List>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Dialog
                          open={isDialogOpen}
                          onClose={handleDialogClose}
                          onExited={handleDialogExited}
                        >
                          <ApiKeyForm
                            onClose={handleDialogClose}
                            onSubmit={handleSubmit}
                            namespaceKeys={namespaceKeys}
                            initialValues={{
                              namespace: '',
                              name: selectedApiKey ? selectedApiKey.name : '',
                              id: selectedApiKey ? selectedApiKey.id : ''
                            }}
                          />
                        </Dialog>
                        <Dialog
                          open={isDeleteDialogOpen}
                          onClose={handleDeleteDialogClose}
                          onExited={handleDialogExited}
                        >
                          <DeleteForm
                            onClose={handleDeleteDialogClose}
                            onSubmit={handleDeleteSubmit}
                            type="API key"
                            initialValues={{ id: selectedApiKey ? selectedApiKey.id : '' }}
                          />
                        </Dialog>
                      </Container>
                    );
                  }}
                </Query>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default ApiKeys;
