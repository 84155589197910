import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Formik, Form, Field } from 'formik';
import PaperBase from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from 'components/TextField';
import FormErrorMessage from 'containers/FormErrorMessage';
import { getValidationErrors } from 'utils/serverError';

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

const Paper = styled(PaperBase)`
  padding: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

const SubmitButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

const TranslationForm = ({ languages, onSubmit, initialValues, onCancel }) => {
  const handleSubmit = async (values, { setSubmitting, setStatus, setErrors }) => {
    try {
      await onSubmit(values);
      setSubmitting(false);
    } catch (error) {
      const validationErrors = getValidationErrors(error);
      if (validationErrors) {
        setErrors(validationErrors);
      }
      setStatus({ error });
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, status }) => (
          <Form>
            <Paper>
              {languages.map(language => (
                <Field
                  type="text"
                  multiline
                  key={language.key}
                  component={TextField}
                  margin="normal"
                  fullWidth
                  id={language.key}
                  label={language.key}
                  name={language.key}
                />
              ))}
              <FormErrorMessage status={status} />
            </Paper>
            <ButtonGroup>
              <Button onClick={onCancel}>
                Cancel
              </Button>
              <SubmitButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Save
              </SubmitButton>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

TranslationForm.fragments = {
  languages: gql`
    fragment TranslationForm_languages on Language {
      key
    }
  `,
  message: gql`
    fragment TranslationForm_message on Message {
      namespace
    }
  `
};

export default TranslationForm;
