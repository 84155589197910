import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation, ApolloConsumer } from 'react-apollo';
import { persistToken } from 'utils/authenticationUtil';
import { isAuthenticated } from 'utils/authenticationUtil';
import LoginForm from './components/LoginForm';

const LoginMutation = gql`
  mutation LoginMutation($input: LoginInput!) {
    login(input: $input) @rest(type: "AuthInfo", path: "authentication/login", method: "POST") {
      token
    }
  }
`;

const LoginPage = ({ location }) => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(null);

  const { from } = location.state || { from: { pathname: '/' } };

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  if (isAuthenticated()) {
    return <Redirect to="/" />;
  }

  return (
    <ApolloConsumer>
      {client => (
        <Mutation
          mutation={LoginMutation}
          onCompleted={({ login }) => {
            persistToken(login.token);
            client.writeData({ data: { isLoggedIn: true } });
          }}
        >
          {login => {
            return <LoginForm login={login} redirect={setRedirectToReferrer} />;
          }}
        </Mutation>
      )}
    </ApolloConsumer>
  );
};

export default LoginPage;
