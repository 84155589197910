import React, { useState } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import paths from 'paths';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Center from 'components/Center';
import Page from 'components/Page';
import MessageListItem from './components/MessageListItem';
import SearchField from './components/SearchField';
import MessageDialog from './containers/MessageDialog';
import MessageContainer from './containers/MessageContainer';

const Container = styled.div``;

const Content = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;

  width: 100%;
  height: 550px;

  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const Toolbar = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const Row = ({ index, style, data }) => {
  const { messages, languages, onClick } = data;
  const message = messages[index];
  return (
    <MessageListItem
      key={index}
      index={index}
      message={message}
      languages={languages}
      onClick={onClick(index)}
      style={style}
    />
  );
};

const filterMessages = (messages, filters) => {
  const { searchQuery, languageFilter = {} } = filters;
  const searchPattern = new RegExp(`${searchQuery}`, 'i');
  const languages = Object.keys(languageFilter).filter(x => languageFilter[x]);

  return messages.filter(message => {
    const { messageId, translations } = message;
    const translationLanguages = translations.map(({ language }) => language);
    const existingTranslations = languages.filter(x => translationLanguages.includes(x));
    return (
      (searchPattern.test(messageId) ||
        translations.filter(({ message }) => searchPattern.test(message)).length) &&
      existingTranslations.length === 0
    );
  });
};

const NamespacePageQuery = gql`
  query NamespacePageQuery($namespace: String!) {
    messages: namespaceMessages(namespace: $namespace)
      @rest(type: "[Message]", path: "namespaces/{args.namespace}/messages") {
      id
      translations {
        language
      }
      ...MessageListItem_message
      ...MessageContainer_messages
    }
    languages @rest(type: "[Language]", path: "languages/") {
      key
      ...MessageListItem_languages
      ...MessageContainer_languages
    }
  }
  ${MessageListItem.fragments.message}
  ${MessageContainer.fragments.messages}
  ${MessageListItem.fragments.languages}
  ${MessageContainer.fragments.languages}
`;

const NamespacePage = ({ match, location, history }) => {
  const { namespace } = match.params;
  const queryParams = qs.parse(location.search, { arrayFormat: 'bracket' });
  const { q: searchQuery = '', languages = [] } = queryParams;
  const languageFilter = languages.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});

  const handleFilterChange = filter => history.replace(paths.namespace(namespace, filter));

  const handleSearchChange = e => {
    handleFilterChange({
      ...queryParams,
      q: e.target.value
    });
  };

  const handleLanguageChange = key => event => {
    const { checked } = event.target;
    handleFilterChange({
      ...queryParams,
      languages: {
        ...languageFilter,
        [key]: checked
      }
    });
  };

  const [isMessageDialogOpen, setMessageDialogOpen] = useState(false);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(0);

  const handleMessageDialogOpen = messageId => () => {
    setSelectedMessageIndex(messageId);
    setMessageDialogOpen(true);
  };

  const handleMessageDialogClose = () => {
    setMessageDialogOpen(false);
  };

  return (
    <Container>
      <Helmet>
        <title>{namespace} | Cooltix Translate</title>
      </Helmet>
      <CssBaseline />
      <Page>
        <Center>
          <Typography variant="h4" component="h1">
            {namespace} messages
          </Typography>
          <Query query={NamespacePageQuery} variables={{ namespace }}>
            {({ loading, error, data }) => {
              if (error) return `Error! ${error.message}`;

              const { messages = [], languages } = data;
              const filteredMessages = filterMessages(messages, {
                searchQuery,
                languageFilter
              });
              const itemCount = filteredMessages.length;

              return (
                <>
                  <Toolbar container spacing={4}>
                    <Grid item xs={12} sm={4} md={3} xl={2}>
                      <SearchField value={searchQuery} onChange={handleSearchChange} />
                    </Grid>
                    <FormControl component={Grid} item xs={12} sm>
                      <FormGroup row>
                        {languages &&
                          languages.map(({ key }) => (
                            <FormControlLabel
                              key={key}
                              onChange={handleLanguageChange(key)}
                              value={key}
                              control={
                                <Checkbox color="primary" checked={languageFilter[key] || false} />
                              }
                              label={key}
                              labelPlacement="end"
                            />
                          ))}
                      </FormGroup>
                    </FormControl>
                  </Toolbar>

                  {!loading && (
                    <Content>
                      <AutoSizer>
                        {({ height, width }) => (
                          <List
                            width={width}
                            height={height}
                            itemCount={itemCount}
                            itemSize={80}
                            itemData={{
                              languages,
                              messages: filteredMessages,
                              onClick: handleMessageDialogOpen
                            }}
                          >
                            {Row}
                          </List>
                        )}
                      </AutoSizer>
                    </Content>
                  )}

                  <MessageDialog
                    open={isMessageDialogOpen}
                    messages={filteredMessages}
                    languages={languages}
                    initialIndex={selectedMessageIndex}
                    onCancel={handleMessageDialogClose}
                  />
                </>
              );
            }}
          </Query>
        </Center>
      </Page>
    </Container>
  );
};

export default NamespacePage;
