import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

const AuthenticationQuery = gql`
  query AuthenticationQuery {
    isLoggedIn @client(always: true)
  }
`;

const ProtectedRoute = ({ component: Component, render, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Query query={AuthenticationQuery}>
        {({ data: { isLoggedIn } }) =>
          isLoggedIn ? (
            render ? render(props) : <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      </Query>
    )}
  />
);

export default ProtectedRoute;
