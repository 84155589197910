import React from 'react';
import gql from 'graphql-tag';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Create from '@material-ui/icons/Create';
import { UserRole } from 'consts';

const UserListItem = ({ user, userRole, onEditClick, onDeleteClick }) => {
  const title = user.name ? `${user.name} <${user.email}>` : user.email;
  const capitalizedRole = `${userRole.charAt(0).toUpperCase()}${userRole.slice(1).toLowerCase()}`;
  const subtitle =
    userRole === UserRole.ADMIN
      ? capitalizedRole
      : `${capitalizedRole}: ${user.namespaces.join(', ')}`;

  return (
    <ListItem>
      <ListItemText primary={title} secondary={subtitle} />
      <ListItemSecondaryAction>
        {user.permissions.canUpdate && (
          <IconButton onClick={onEditClick(user.id)}>
            <Create />
          </IconButton>
        )}
        {user.permissions.canDelete && (
          <IconButton edge="end" onClick={onDeleteClick(user.id)}>
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

UserListItem.fragments = {
  user: gql`
    fragment UserListItem_user on User {
      id
      name
      email
      namespaces
      permissions {
        canUpdate
        canDelete
      }
    }
  `
};

export default UserListItem;
