import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import ListItemBase from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const ListItem = styled(ListItemBase)`
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  padding-right: ${({ theme }) => theme.spacing(3)}px;

  background-color: ${({ index, theme }) =>
    index % 2 === 0 ? 'transparent' : theme.palette.grey[100]};
`;

const MessageListItem = ({ message, languages, to, ...props }) => (
  <ListItem button {...props}>
    <Grid container alignItems="center">
      <Grid item xs={6} sm={5} md={4} lg={3}>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="subtitle2" component="h2">
              {message.messageId}
            </Typography>
          }
          secondary={
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {message.translations.length}/{languages.length} translations
            </Typography>
          }
        />
      </Grid>
      <Grid item xs>
        <Typography>{message.defaultMessage}</Typography>
      </Grid>
    </Grid>
  </ListItem>
);

MessageListItem.fragments = {
  message: gql`
    fragment MessageListItem_message on Message {
      messageId
      defaultMessage
      translations {
        id
      }
    }
  `,
  languages: gql`
    fragment MessageListItem_languages on Language {
      id
    }
  `
};

export default MessageListItem;
