import React from 'react';
import styled from 'styled-components';
import TextField from 'components/TextField';
import { Formik, Form, Field } from 'formik';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormErrorMessage from 'containers/FormErrorMessage';
import MenuItem from '@material-ui/core/MenuItem';
import { getValidationErrors } from 'utils/serverError';

const Container = styled.div``;

const ApiKeyForm = ({ namespaceKeys, onSubmit, onClose, initialValues }) => {
  const handleSubmit = async (values, { setSubmitting, setStatus, setErrors }) => {
    try {
      await onSubmit(values);
      setSubmitting(false);
    } catch (error) {
      const validationErrors = getValidationErrors(error);
      if (validationErrors) {
        setErrors(validationErrors);
      }
      setStatus({ error });
      setSubmitting(false);
    }
  };

  const isEditing = initialValues.id !== '';

  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={false}>
        {({ isSubmitting, status }) => (
          <Form>
            <DialogTitle>{isEditing ? 'Update API key' : 'Add API key'}</DialogTitle>
            <DialogContent>
              {!isEditing && (
                <Field
                  select
                  component={TextField}
                  margin="normal"
                  fullWidth
                  label="Namespace"
                  name="namespace"
                  autoFocus={!isEditing}
                >
                  {namespaceKeys.map(key => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Field>
              )}
              <Field
                type="text"
                component={TextField}
                margin="normal"
                fullWidth
                label="Name (optional)"
                name="name"
                autoFocus={isEditing}
              />
              <FormErrorMessage status={status} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ApiKeyForm;
