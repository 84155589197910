import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import client from 'client';
import App from 'containers/App';
import theme from 'muiTheme';

const Root = () => (
  <ApolloProvider client={client}>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </ApolloProvider>
);

export default Root;
