import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AddButton from 'components/AddButton';
import LanguageCard from '../components/LanguageCard';
import LanguageDialog from '../components/LanguageDialog';

const Container = styled.div``;

const Content = styled(Grid).attrs({ container: true, spacing: 2 })`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

const LanguagesFragment = gql`
  fragment Languages_languages on Language {
    id
    ...LanguageCard_language
  }
  ${LanguageCard.fragments.language}
`;

const LanguagesQuery = gql`
  query LanguagesQuery {
    languages @rest(type: "[Language]", path: "languages/") {
      ...Languages_languages
    }
  }
  ${LanguagesFragment}
`;

const LanguagesContainerQuery = gql`
  query LanguagesContainerQuery {
    languages @rest(type: "[Language]", path: "languages/") {
      ...Languages_languages
    }
    viewer @rest(type: "User", path: "users/current") {
      userPermissions {
        canCreateLanguage
      }
    }
  }
  ${LanguagesFragment}
`;

const CreateLanguage = gql`
  mutation CreateLanguage($input: CreateLanguageInput!) {
    createLanguage(input: $input) @rest(type: "Language", path: "languages/", method: "POST") {
      ...Languages_languages
    }
  }
  ${LanguagesFragment}
`;

const UpdateLanguageMutation = gql`
  mutation UpdateLanguageMutation($id: ID!, $input: UpdateLanguageInput!) {
    updateLanguage(id: $id, input: $input)
      @rest(type: "Language", path: "languages/{args.id}", method: "PUT") {
      ...Languages_languages
    }
  }
  ${LanguagesFragment}
`;

const Languages = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  return (
    <Mutation
      mutation={CreateLanguage}
      update={(cache, { data: { createLanguage } }) => {
        const { languages } = cache.readQuery({ query: LanguagesQuery });
        cache.writeQuery({
          query: LanguagesQuery,
          data: { languages: [...languages, createLanguage] }
        });
      }}
    >
      {createLanguage => (
        <Mutation mutation={UpdateLanguageMutation}>
          {updateLanguage => (
            <Query query={LanguagesContainerQuery}>
              {({ loading, error, data }) => {
                if (loading) return 'Loading...';
                if (error) return `Error! ${error.message}`;

                const handleDialogClose = () => {
                  setDialogOpen(false);
                };

                const handleCreateClick = () => {
                  setDialogOpen(true);
                };

                const handleDialogOpen = id => () => {
                  setSelectedLanguage(languages.find(language => language.id === id));
                  setDialogOpen(true);
                };

                const handleSubmit = async ({ id, ...input }) => {
                  if (id === '') {
                    await createLanguage({ variables: { input } });
                  } else {
                    await updateLanguage({ variables: { id, input } });
                  }
                  handleDialogClose();
                };
                const handleDialogExited = () => {
                  setSelectedLanguage(null);
                };

                const { languages, viewer } = data;
                return (
                  <Container>
                    {viewer.userPermissions.canCreateLanguage && (
                      <AddButton onClick={handleCreateClick}>Add language</AddButton>
                    )}
                    <Content>
                      {languages.map(language => (
                        <Grid key={language.key} item xs={6} sm={4} md={3} lg={2}>
                          <LanguageCard language={language} onClick={handleDialogOpen} />
                        </Grid>
                      ))}
                    </Content>
                    <Dialog
                      open={isDialogOpen}
                      onClose={handleDialogClose}
                      onExited={handleDialogExited}
                    >
                      <LanguageDialog
                        onClose={handleDialogClose}
                        onSubmit={handleSubmit}
                        initialValues={{
                          id: selectedLanguage ? selectedLanguage.id : '',
                          name: selectedLanguage ? selectedLanguage.name : '',
                          key: selectedLanguage ? selectedLanguage.key : '',
                          currency: selectedLanguage ? selectedLanguage.currency : '',
                          currencyKey: selectedLanguage ? selectedLanguage.currencyKey : '',
                          isPublished: selectedLanguage ? selectedLanguage.isPublished : false
                        }}
                      />
                    </Dialog>
                  </Container>
                );
              }}
            </Query>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default Languages;
