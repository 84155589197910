import React from 'react';
import gql from 'graphql-tag';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Badge from './Badge';

const LanguageCard = ({ language, onClick }) => (
  <Card>
    <CardActionArea disabled={!language.permissions.canUpdate} onClick={onClick(language.id)}>
      <CardContent>
        <Grid container>
          <Grid item xs>
            <Typography variant="h5" component="h2">
              {language.key}
            </Typography>
            <Typography color="textSecondary">
              {[language.name, language.currencyKey, language.currency].filter(x => x).join(' ∙ ')}
            </Typography>
          </Grid>
          <Grid item>
            {language.isPublished ? <Badge status="success" /> : <Badge status="gray" />}
          </Grid>
        </Grid>
      </CardContent>
    </CardActionArea>
  </Card>
);

LanguageCard.fragments = {
  language: gql`
    fragment LanguageCard_language on Language {
      name
      key
      currency
      currencyKey
      permissions {
        canUpdate
      }
      isPublished
    }
  `
};

export default LanguageCard;
