import React from 'react';
import gql from 'graphql-tag';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Create from '@material-ui/icons/Create';

const ApiKeyListItem = ({ apiKey, onDeleteClick, onEditClick }) => (
  <ListItem>
    <ListItemText primary={apiKey.token} secondary={apiKey.name} />
    <ListItemSecondaryAction>
      <IconButton onClick={onEditClick(apiKey.id)}>
        <Create />
      </IconButton>
      <IconButton edge="end" onClick={onDeleteClick(apiKey.id)}>
        <DeleteIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);

ApiKeyListItem.fragments = {
  apiKey: gql`
    fragment ApiKeyListItem_apiKey on ApiKey {
      id
      token
      name
    }
  `
};

export default ApiKeyListItem;
