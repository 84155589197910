import React from 'react';

const Logo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.1 8.13h-8.17a.97.97 0 0 0-.95 1.14l2.15 11.08h-8.76a.97.97 0 0 0-.95 1.15l1.55 7.72c.1.47.5.76.91.78H27.1c1.6 0 2.9-1.3 2.9-2.9V11.02c0-1.6-1.3-2.9-2.9-2.9z"
        fill="#B8C9D9"
        fillRule="nonzero"
      />
      <path
        d="M27.18 16.37h-2.94v-1c0-.55-.44-1-.98-1a.99.99 0 0 0-.98 1v1h-2.94a.99.99 0 0 0-.98 1c0 .56.44 1 .98 1h1.06c.19 1.2.72 2.1 1.5 3l-1.29 1.3a1.01 1.01 0 0 0 0 1.41c.38.4 1 .4 1.39 0l1.26-1.27 1.27 1.27c.38.4 1 .4 1.38 0 .39-.39.39-1.02 0-1.41l-1.28-1.3a5.87 5.87 0 0 0 1.5-3h1.05c.54 0 .98-.44.98-1 0-.55-.44-1-.98-1zm-3.92 3.56c-.43-.52-.71-1-.86-1.56h1.72a4.06 4.06 0 0 1-.86 1.56z"
        fill="#E6F3FF"
        fillRule="nonzero"
      />
      <path
        d="M21.97 20.64a.95.95 0 0 0-.88-.64h-9.74a.93.93 0 0 0-.73.37c-.18.23-.25.53-.2.83l1.52 8a.96.96 0 0 0 .98.8c.2-.01.42-.1.6-.27l8.21-8c.3-.27.39-.71.24-1.1z"
        fill="#2860CC"
        fillRule="nonzero"
      />
      <path
        d="M21.1 21.88H3.44a2.87 2.87 0 0 1-2.84-2.9V2.9C.61 1.3 1.88 0 3.45 0H17.3c.45 0 .84.33.93.78l3.78 19.95a.98.98 0 0 1-.2.8.94.94 0 0 1-.73.34z"
        fill="#167EE6"
        fillRule="nonzero"
      />
      <g fill="#E6F3FF">
        <path d="M9.7 5.3c-1.57 0-3.1.6-4.15 1.85-3.28 3.97-1.4 5.75.94 8.41a4.43 4.43 0 0 0 3.4 1.47c1.68 0 3.43-.8 4.58-2.18 2-2.43 1.69-6.04-.7-8.07a6.34 6.34 0 0 0-4.08-1.49m0 .71c1.31 0 2.63.49 3.62 1.32a5.06 5.06 0 0 1 .62 7.07 5.39 5.39 0 0 1-4.04 1.93c-.81 0-1.97-.21-2.87-1.24l-.3-.33c-2.18-2.46-3.38-3.81-.63-7.14a4.57 4.57 0 0 1 3.6-1.6" />
        <path d="M10.12 5.71c-1.5 0-2.93.6-3.89 1.75-2.94 3.58-1.6 5.1.64 7.63l.3.34a4.1 4.1 0 0 0 3.2 1.35 5.75 5.75 0 0 0 4.22-2.06 5.46 5.46 0 0 0-.66-7.6 5.9 5.9 0 0 0-3.81-1.4m.17 11.78a4.77 4.77 0 0 1-3.65-1.6l-.29-.33c-2.23-2.52-4-4.52-.66-8.57 2.06-2.49 6.04-2.68 8.7-.42a6.18 6.18 0 0 1 .74 8.6 6.38 6.38 0 0 1-4.84 2.32" />
        <path d="M9.86 5.48c-1.54 0-3.02.6-4 1.8-3.03 3.67-1.57 5.32.64 7.83l.3.33c.81.93 1.99 1.43 3.31 1.4a5.9 5.9 0 0 0 4.34-2.11 5.6 5.6 0 0 0-.68-7.8 6.06 6.06 0 0 0-3.91-1.45m.17 11.84c-1.42 0-2.7-.55-3.58-1.56l-.3-.33c-2.2-2.5-3.94-4.47-.65-8.46 2.02-2.46 5.95-2.64 8.57-.4a6.09 6.09 0 0 1 .74 8.46 6.3 6.3 0 0 1-4.78 2.3" />
        <path d="M8.34 11.13c0-1.02.82-1.86 1.82-1.87.43 0 .83.15 1.14.4.12.11.14.3.05.42-.1.13-.28.16-.4.06a1.2 1.2 0 0 0-.8-.28c-.34 0-.65.14-.89.37a1.31 1.31 0 0 0 0 1.8c.24.24.55.38.9.38.3 0 .57-.1.8-.29a.3.3 0 0 1 .4.06c.08.13.06.32-.06.42-.31.25-.7.4-1.14.4-1 0-1.82-.83-1.82-1.87" />
      </g>
    </g>
  </svg>
);

export default Logo;
