import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import AddIconBase from '@material-ui/icons/Add';

const AddIcon = styled(AddIconBase)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const AddButton = ({ children, ...props }) => (
  <Button variant="contained" color="primary" {...props}>
    <AddIcon />
    {children}
  </Button>
);

export default AddButton;
