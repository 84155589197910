import React from 'react';
import styled from 'styled-components';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIconBase from '@material-ui/icons/Error';
import { getErrorMessage } from 'utils/serverError';

const ErrorSnackBar = styled(SnackbarContent)`
  margin-top: ${props => props.theme.spacing(2)}px;
  background-color: ${props => props.theme.palette.error.main};
`;

const SnackBarMessage = styled.div`
  display: flex;
  align-items: center;
`;

const ErrorIcon = styled(ErrorIconBase)`
  margin-right: ${props => props.theme.spacing(1)}px;
`;

const ErrorMessage = ({ message, ...rest }) => (
  <ErrorSnackBar
    message={
      <SnackBarMessage>
        <ErrorIcon />
        {message}
      </SnackBarMessage>
    }
    {...rest}
  />
);

const FormErrorMessage = ({ status = {} }) => {
  const { error } = status;
  const errorMessage = getErrorMessage(error);
  return errorMessage ? <ErrorMessage message={errorMessage} /> : null;
};

export default FormErrorMessage;
