import React from 'react';
import MuiTextField from '@material-ui/core/TextField';

const TextField = ({ field, form, ...rest }) => (
  <MuiTextField
    {...field}
    disabled={form.isSubmitting}
    error={form.touched[field.name] && !!form.errors[field.name]}
    helperText={
      form.touched[field.name] && form.errors[field.name]
    }
    {...rest}
  />
);

export default TextField;
