import React from 'react';
import gql from 'graphql-tag';
import Link from 'containers/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

const NamespaceCard = ({ namespace, to }) => (
  <Card>
    <CardActionArea component={Link} to={to}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {namespace.key}
        </Typography>
        <Typography color="textSecondary">{namespace.messageCount} messages</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

NamespaceCard.fragments = {
  namespace: gql`
    fragment NamespaceCard_namespace on Namespace {
      key
      messageCount
    }
  `
}

export default NamespaceCard;
