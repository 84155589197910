import React from 'react';
import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const Container = styled.div`
  position: relative;

  margin-left: 0;
  margin-right: ${({ theme }) => theme.spacing(2)}px;

  width: 100%;

  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`;

const IconHolder = styled.div`
  display: flex;
  position: absolute;

  align-items: center;
  justify-content: center;

  width: ${({ theme }) => theme.spacing(7)}px;
  height: 100%;

  pointer-events: none;
`;

const Input = styled(InputBase)`
  padding: ${({ theme }) => theme.spacing(1, 1, 1, 7)};

  width: 100%;
`

const SearchField = ({ children, ...props }) => (
  <Container>
    <IconHolder>
      <SearchIcon />
    </IconHolder>
    <Input placeholder="Search" inputProps={{ 'aria-label': 'Search' }} {...props} />
  </Container>
);

export default SearchField;
