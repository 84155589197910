import React, { forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import MessageContainer from './MessageContainer';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MessageDialog = ({ open, onCancel, ...rest }) => (
  <Dialog
    open={open}
    onClose={onCancel}
    fullScreen={true}
    TransitionComponent={Transition}
    hideBackdrop={true}
  >
    <MessageContainer onCancel={onCancel} {...rest} />
  </Dialog>
);

export default MessageDialog;
