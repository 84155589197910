import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import paths from 'paths';
import Grid from '@material-ui/core/Grid';
import NamespaceCard from '../components/NamespaceCard';

const Content = styled(Grid).attrs({ container: true, spacing: 2 })`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

const NamespacesQuery = gql`
  query NamespacesQuery {
    namespaces @rest(type: "[Namespace]", path: "namespaces") {
      key
      ...NamespaceCard_namespace
    }
  }
  ${NamespaceCard.fragments.namespace}
`;

const Namespaces = () => (
  <Query query={NamespacesQuery}>
    {({ loading, error, data }) => {
      if (loading) return 'Loading...';
      if (error) return `Error! ${error.message}`;
      return (
        <Content>
          {data.namespaces.map(namespace => (
            <Grid key={namespace.key} item xs={6} sm={3}>
              <NamespaceCard namespace={namespace} to={paths.namespace(namespace.key)} />
            </Grid>
          ))}
        </Content>
      );
    }}
  </Query>
);

export default Namespaces;
