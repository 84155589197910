import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from 'containers/ProtectedRoute';
import Header from 'components/Header';

// import protected screens
import DashboardPage from 'pages/DashboardPage';
import NamespaceMessagesPage from 'pages/NamespacePage';

const MainQuery = gql`
  query MainQuery {
    viewer @rest(type: "User", path: "users/current") {
      ...DashboardPage_viewer
      ...Header_viewer
    }
    isLoggedIn @client(always: true)
  }
  ${DashboardPage.fragments.viewer}
  ${Header.fragments.viewer}
`;

const Main = () => (
  <Query query={MainQuery}>
    {({ loading, data = {} }) => {
      if (loading) return null;
      
      const { viewer, isLoggedIn } = data;
      return (
        <>
          <Header isLoggedIn={isLoggedIn} viewer={viewer} />
          <Switch>
            <ProtectedRoute
              exact
              path="/"
              render={props => <DashboardPage {...props} viewer={viewer} />}
            />
            <ProtectedRoute
              exact
              path="/:tab"
              render={props => <DashboardPage {...props} viewer={viewer} />}
            />
            <ProtectedRoute exact path="/namespaces/:namespace" component={NamespaceMessagesPage} />
            <Redirect to="/" />
          </Switch>
        </>
      );
    }}
  </Query>
);

export default Main;
