import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { RestLink } from 'apollo-link-rest';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { retrieveToken, logout, isAuthenticated } from 'utils/authenticationUtil';

import { SERVER_URL } from 'consts';

const cache = new InMemoryCache();

// setup `RestLink` with REST endpoint
const restLink = new RestLink({
  uri: SERVER_URL,
  typePatcher: {
    Message: (data, outerType, patchDeeeper) => {
      if (data.translations !== null) {
        data.translations = data.translations.map(translation => ({
          __typename: 'Translation',
          ...translation
        }));
      }
      return data;
    },
    User: (data, outerType, patchDeeeper) => {
      if (data.userPermissions) {
        data.userPermissions = {
          __typename: 'UserRolePermissions',
          ...data.userPermissions
        };
      }
      if (data.permissions) {
        data.permissions = {
          __typename: 'UserPermissions',
          ...data.permissions
        };
      }
      return data;
    },
    Language: data => {
      if (data.permissions) {
        data.permissions = {
          __typename: 'LanguagePermissions',
          ...data.permissions
        };
      }
      return data;
    }
  }
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: retrieveToken()
  }
}));

const logoutLink = onError(({ networkError }) => {
  if (networkError.statusCode === 401) {
    logout();
    cache.writeData({ data: { isLoggedIn: false } });
  }
});

// setup client
const client = new ApolloClient({
  link: ApolloLink.from([authLink, logoutLink, restLink]),
  cache,
  resolvers: {}
});

// Initialize state
const initialState = {
  isLoggedIn: isAuthenticated()
};

cache.writeData({ data: initialState });
client.onResetStore(() => cache.writeData({ data: initialState }));

export default client;
